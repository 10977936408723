import ajax from './ajax'

// 定义基础路径
export const TEST_URL = ''

//公共资源请求 
export const artivlePublic = (id) => ajax(TEST_URL + '/v1/training/article/' + id, {}, 'GET')
export const getVideoShare = (id) => ajax(TEST_URL + '/v1/training/video/' + id, {}, 'GET', {}, true)
export const getArticalShare = (id) => ajax(TEST_URL + '/v1/training/article/' + id, {}, 'GET', {}, true)

