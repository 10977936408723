import axios from 'axios'
// import Promise from 'core-js/features/promise';
import {message} from 'antd'
import qs from 'qs'
//axios请求拦截
axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token")?localStorage.getItem("token"):'';
      config.headers = {
        'token':token,
        ...config.headers
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);
export default function ajax(
    url = '',
    params = {},
    type = 'GET',
    headers,
    isLoading,
    customError
) {
    let promise, loading;
    if (isLoading == true) {
        loading = document.getElementById('ajaxLoading')
        loading.style.display = 'block'
    }
    // 1. 返回promise对象
    return new Promise((resolve, reject) => {
        if ('GET' === type.toUpperCase()) {
            promise = axios.get(url, {
                headers: {
                    ...headers
                },
                params: params
            });

        } else if ('POST' === type.toUpperCase()) {
            if(headers&&(headers['Content-Type'] === 'application/x-www-form-urlencoded'|| headers['Content-Type'] === 'multipart/form-data')){
                promise = axios.post(url,qs.stringify(params,{ indices: false }),{
                    headers: {
                        ...headers
                    }
                });
            }else{
                promise = axios.post(url,params,{
                    headers: {
                        ...headers
                    }
                });
            }
        } else if ('POST_QUERY' === type.toUpperCase()) {
            promise = axios.post(url, {}, {
                params
            });
        }
        // 1.6 返回结果
        promise.then((response) => {
            if (isLoading == true) {
                loading = document.getElementById('ajaxLoading')
                loading.style.display = 'none'
            }
            //拦截
            if(response.data.state && response.data.state.value === 403){//无权限
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                    // localStorage.removeItem("token");
                    // window.location.href = '/';
                } else {
                    // localStorage.removeItem("token");
                    // window.location.href = '/';
                    reject(response.data.content)
                }
            }else if(response.data.state && response.data.state.value === 401){ //登陆失效
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                    reject(response.data.content)
                }
            }else if(response.data.state && response.data.state.value === 402){ //登陆失败
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                    localStorage.removeItem("token");
                } else {
                    //console.log(response.data);
                    localStorage.removeItem("token");
                    reject(response.data.content)
                }
                
            }else if(response.data.state && response.data.state.value === 400){
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                    //window.location.href = '/login';
                } else {
                    //console.log(response.data);
                    //window.location.href = '/login';
                    reject(response.data.content)
                }
                
            }else if(response.data.state && response.data.state.value === 500){
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                    //window.location.href = '/login';
                } else {
                    //console.log(response.data);
                    //window.location.href = '/login';
                    reject(response.data.content)
                }
                
            }else if(response.data.state && response.data.state.value === 2){ //重复提交
                if (!customError) {
                    //console.log(response.data);
                    alert(response.data.content);
                } else {
                    //console.log(response.data);
                    reject(response.data.content)
                }
            }
            resolve(response);
        }).catch(error => {
            reject(error);
            if (isLoading == true) {
                loading = document.getElementById('ajaxLoading')
                loading.style.display = 'none'
                alert('加载失败!请稍后再试!')
            }
        })

    })
}