// import { type } from '../Store/actionTypes'
import * as constants from './actionTypes'

//默认的数据
const defaultState = {
    userData:{}
    //cropadmin:[]
};

export default (state = defaultState, action) => {
    if(action.type === constants.INIT_USER_DATA){ //登录
        const newState = JSON.parse(JSON.stringify(state));
        //把用户数据存入本地
        //console.log(action,"action")
        //debugger
        localStorage.setItem('userData',JSON.stringify(action.userData));
        newState.userData = action.userData;
        return newState;
    }
    return state;
}
