import React, { Component } from 'react'
import { artivlePublic } from '../Api'


/**
 * @classdesc 新增编辑文章和法规的父组件
*/
class AddLook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ''
        };
    }

    componentWillMount() {
        this.getArtivlePublic()
    }
    getArtivlePublic = () => {
        let _this = this
        let id = this.props.match.params.id
        artivlePublic(id).then(res => {
            if (res.data.state.value === 0) {
                _this.setState({
                    content: res.data.result.content
                })
                document.title = res.data.result.name
                var i = document.createElement('iframe');
                i.src = '/favicon.ico';
                i.style.display = 'none';
                i.onload = function() {
                setTimeout(function(){
                    i.remove();
                }, 20)
                }
                document.body.appendChild(i);
            }
        })
    }

    render() {
        return (
            <div className="home" style={{ padding: '10px 20px' }}>
                <div
                    dangerouslySetInnerHTML={{ __html: this.state.content }}
                    className='family-inherit-text'
                >
                </div>
            </div>
        )
    }
}
export default AddLook;