import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {getVideoShare} from './../../Api'
import {
    Row,
    Col
} from 'antd'
import './index.css'
import moment from 'moment'
/**
 * @description 视频列表
 * @class Video
 * @extends {Component}
 * */
class Video extends Component {
    state = {
        title: "",
        url: "",
        source: "",
        description: "",
        date: "",
        block: false,
        isMp4: true
    }
    componentWillMount() {
        let id = this.props.match.params.id

        getVideoShare(id).then(res => {
            console.log(res)
            if (res.data.result) {
                if (res.data.result.url.indexOf("oss-cn-hangzhou.aliyuncs.com") < 0) {
                    this.setState({isMp4: false});
                } else {
                    //直接添加的video播放地址依赖第三方-用iframe显示
                    this.setState({isMp4: true});
                }
                this.setState({
                    block: true,
                    url: res.data.result.url,
                    source: res.data.result.source,
                    date: moment(res.data.result.updateDate).format("YYYY-MM-DD HH:MM:SS"),
                    title: res.data.result.name,
                    description: res.data.result.description
                });
            }
        })
    }
    render() {
        let {
            isMp4,
            title,
            url,
            source,
            description,
            date,
            block
        } = this.state
        return (
            <div className="video-share">
                {block
                    ? <div>
                            <Row type="flex" justify="start">
                                <Col span={24}>
                                    {isMp4
                                        ? <video src={url} width="100%" height="500" controls/>
                                        : <iframe
                                            title={title}
                                            src={url}
                                            allowFullScreen="allowfullscreen"
                                            width="100%"
                                            height="500"
                                            scrolling="no"
                                            frameBorder="0"
                                            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe>
                                    }
                                </Col>
                            </Row>
                            <Row type="flex" justify="start">
                                <Col span={24} className="title">{title}</Col>
                            </Row>
                            <Row type="flex" justify="start">
                                <Col span={24}>
                                    <div className="sub-title">
                                        <span>{source}</span>
                                        <span className="date">{date}</span>
                                    </div>

                                </Col>
                            </Row>
                            <Row type="flex" justify="start">
                                <Col span={24}>{description}</Col>
                            </Row>
                        </div>
                    : <div className="miss-block">
                        请 稍 后 再 试 ...
                    </div>
            }
            </div>
        )
    }
}

export default withRouter(Video)