
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {Provider} from 'react-redux'
import store from './Store'
import 'font-awesome/css/font-awesome.css'
import './Common/css/base.css'
import './Common/css/path.css'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn'
import moment from 'moment'
// import mock from './Mock/mock'
moment.locale('zh-cn');
ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <App />
        </ConfigProvider>
    </Provider>
, document.getElementById('root'));
