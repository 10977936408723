/*
 * @Author: 沈晨露
 * @Date: 2020-05-09 08:04:55
 * @LastEditTime: 2020-06-24 08:34:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \运营后台4.0\src\App.js
 */ 
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch,  } from "react-router-dom";
import AddLook from './Pages/AddLook'
import VideoShare from './Pages/Share/video'
import Region from './Pages/Share'
import ArticalShare from './Pages/Share/artical'

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/addLook/:id" component={AddLook} exact />
                    <Route path="/videoShare/:id" component={VideoShare} exact />
                    <Route path="/region/:id" component={Region} exact />
                    <Route path="/articalShare/:id" component={ArticalShare} exact />
                </Switch>
            </Router>
        );
    }
}



export default App;